<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Produit</label>
        <select v-model="order_item.item_reference" class="form-select">
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for=""> Remarque </label>
        <input v-model="order_item.remark" class="form-control" />
      </div>

      <div class="col-1">
        <label for=""> Qté </label>
        <input type="text" v-model="order_item.quantity" class="form-control" />
      </div>

      <div class="col-1">
        <label for=""> Prix </label>
        <input type="text" v-model="order_item.priceTTC" class="form-control" />
      </div>

      <div class="col">
        <button
          v-if="order_item"
          @click="save(order_item)"
          class="btn btn-info"
        >
          <i class="bi bi-plus-circle"></i>
          Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-4">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Désignation</th>
              <th scope="col">Quantité</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="orders != null">
            <tr v-for="(order, index) in filteredOrders" :key="index">
              <td>
                {{ index }}
              </td>
              <td>
                {{ order.item_reference }}

                <span v-if="order.item">
                  {{ order.item.fullName }}
                </span>
              </td>
              <td>{{ order.quantity }}</td>

              <td class="p-0 m-0">
                <button @click="order_item = order" class="btn">
                  <i class="bi bi-info-square fs-5"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-8">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Désignation</th>
              <th scope="col">Quantité</th>
              <th scope="col">Prix</th>
              <th scope="col">Total</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orderSupplierItems" :key="index++">
              <td>
                {{ index }}
              </td>
              <td>
                {{ order.item_reference }}

                <span v-if="order.item">
                  {{ order.item.fullName }}
                </span>
                <span v-if="order.remark"> ({{ order.remark }}) </span>
              </td>
              <td>{{ order.quantity }}</td>
              <td>{{ order.priceTTC }}</td>
              <td>{{ order.totalPriceTTC }}</td>

              <td class="p-0 m-0">
                <button @click="removeItem(order.id)" class="btn">
                  <i class="bi bi-trash text-danger"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          type="button"
          @click="$router.push({ name: 'orders-suppliers-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-arrow-right-circle"></i>
          Suivant
        </button>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      order_item: {},
      section_reference: "",
      accept: false,
    };
  },
  computed: {
    ...mapGetters("order", {
      orders: "getSupplierOrders",
      orderSupplierItems: "getOrderSupplierItems",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    filteredOrders() {
      if (this.orders)
        return this.orders.filter((order) => {
          return !this.orderSupplierItems.some(
            (supplierItem) =>
              supplierItem.item_reference === order.item_reference
          );
        });
      else return [];
    },
  },
  created() {
    this.$store.dispatch("order/getSupplierOrders");
    this.$store.dispatch("order/getOrderSupplierItem");

    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async refresh() {
      this.$store.dispatch("order/getSupplierOrders");
      this.$store.dispatch("order/getOrderSupplierItem");

      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
    },

    async save(data) {
      await this.$store.dispatch("order/storeOrderSupplierItem", data);
      await (this.order_item = {});
    },

    async removeItem(id) {
      return await this.$store.dispatch("order/destroyOrderSupplierItem", id);
    },
  },
};
</script>
